<ng-template cdkPortal>
  <div class="modal" [style.min-width]="minWidth" [@fadeInAnimation] [@fadeOutAnimation]>
    @if (!hideHeader) {
      <div class="header">
        <ng-content select="[header]"></ng-content>
        @if (!hideCloseButton) {
          <app-button class="close-button" (btnClick)="closeModal.emit()">
            <fa-icon [icon]="['fal', 'xmark']"></fa-icon>
          </app-button>
        }
      </div>
    }
    <div class="content"><ng-content select="[content]"></ng-content></div>
    <div class="footer"><ng-content select="[footer]"></ng-content></div>
  </div>
</ng-template>

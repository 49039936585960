import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  SecurityContext
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Auth,
  CheckValidSessionUseCase,
  GetLoggedUserUsecase,
  GetOrganizationsUseCase,
  LogOutUseCase,
  SaveUserAccountUsecase
} from '@core/core';
import { Failure, Success } from '@sdk/sdk';

import { DomSanitizer } from '@angular/platform-browser';
import { Organization } from '@core/auth/domain/organization';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { OrganizationRedirectHelper } from './organization-redirect-helper';

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationListComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  isError = false;
  isLoading = false;
  isRedirecting?: Organization;
  isLoggingOut = false;
  organizations: Organization[] = [];
  loggedUser?: Auth;
  showSearch = false;
  searching = false;
  search$ = new Subject<string>();
  licenseError = false;

  constructor(
    private _checkSessionUseCase: CheckValidSessionUseCase,
    private _getOrganizationsUsecase: GetOrganizationsUseCase,
    private _saveUserAccountUsecase: SaveUserAccountUsecase,
    private _getLoggedUserUsecase: GetLoggedUserUsecase,
    private _logOutUsecase: LogOutUseCase,
    private _router: Router,
    private _route: ActivatedRoute,
    private _organizationRedirect: OrganizationRedirectHelper,
    private _changeDetector: ChangeDetectorRef,
    private _sanitizer: DomSanitizer
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    this._checkLicenseError();
    const hasSession = await this._checkHasSession();
    if (hasSession) {
      await this._loadOrganizationList();
      this._handleRedirect();
    }
    const loggedUserResult = await this._getLoggedUserUsecase.execute();
    if (loggedUserResult instanceof Success) {
      this.loggedUser = loggedUserResult.value;
    }
    this._initSearch();
    this._changeDetector.markForCheck();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  async logOut(): Promise<void> {
    this.isLoggingOut = true;
    await this._logOutUsecase.execute();
    this._router.navigate(['/login']);
  }

  onSearchChanged(searchText: string): void {
    this.search$.next(searchText);
    this._changeDetector.markForCheck();
  }

  redirectingTo(organization: Organization): boolean {
    return this.isRedirecting?.url.host == organization.url.host && this.isRedirecting?.id == organization.id;
  }

  async organizationClick(organization: Organization, event: MouseEvent): Promise<void> {
    // If not left or middle button, do nothing
    if (event.button > 1) {
      return;
    }
    if (!this.loggedUser) {
      return;
    }
    await this._saveUserAccountUsecase.execute(
      this.loggedUser.email,
      this.loggedUser.name,
      organization,
      this.loggedUser.avatar
    );
    const url = `${organization.url}o/${organization.id}/`;
    if (event.button == 0) {
      this.isRedirecting = organization;
      window.location.href = url;
      return;
    }
    window.open(url, '_blank');
  }

  closeDialog(): void {
    this.licenseError = false;
  }

  private _initSearch(): void {
    this.search$
      .pipe(distinctUntilChanged(), debounceTime(0), takeUntil(this._destroy$))
      .subscribe(searchText => {
        this.searching = true;
        if (searchText) {
          this._search(searchText);
        } else {
          this._loadOrganizationList();
        }
      });
  }

  private async _search(searchText: string): Promise<void> {
    this.organizations = this.organizations.filter(organization =>
      organization.name.toLowerCase().includes(searchText.toLowerCase())
    );
    this._changeDetector.markForCheck();
  }

  private async _loadOrganizationList(): Promise<void> {
    this.isLoading = true;
    const result = await this._getOrganizationsUsecase.execute();
    this.isLoading = false;
    if (result instanceof Failure) {
      this.isError = true;
      return;
    }
    this.organizations = result.value;
    if (this.organizations.length > 5) {
      this.showSearch = true;
    }
    this._changeDetector.markForCheck();
  }

  private _handleRedirect(): void {
    const redirectTo = this._route.snapshot.queryParamMap.get('r');
    if (!redirectTo) {
      if (this.organizations.length != 1) {
        return;
      }

      window.location.href = this._organizationRedirect.getOrganizationUrl(this.organizations[0]);
      return;
    }

    const sanitizedRedirectTo = this._sanitizer.sanitize(SecurityContext.URL, redirectTo);

    if (!sanitizedRedirectTo || sanitizedRedirectTo.startsWith('unsafe:')) {
      return;
    }

    const redirectUrl = this._organizationRedirect.getRedirectUrl(this.organizations, sanitizedRedirectTo);

    if (redirectUrl) {
      this.isRedirecting = this._organizationRedirect.getOrganizationFromUrl(this.organizations, redirectUrl);
      window.location.href = redirectUrl;
    }
  }

  private async _checkHasSession(): Promise<boolean> {
    const result = await this._checkSessionUseCase.execute();
    if (result instanceof Failure) {
      this._router.navigate(['/login']);
      return false;
    }
    return true;
  }
  private _checkLicenseError(): void {
    const error = this._route.snapshot.queryParamMap.get('error');
    if (error === 'NO_LICENSE') {
      this.licenseError = true;
    }
  }
}
